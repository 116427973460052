<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        สร้างใบรับสินค้าเข้า : TO3-1
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >เพิ่มใบรับสินค้า
        </button>
      </div>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
          @click="search()"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัส Transfer</th>
              <th>ชื่อ Transfer</th>
              <th>วันที่สร้างเอกสาร</th>
              <th>มูลค่า</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>
                <a style="font-weight: bold" @click="goToItemDetail(item)"
                  ><u>
                    {{ item.transfer_no ? item.transfer_no : "" }}
                  </u>
                </a>
              </td>
              <td>
                {{ item.transferee_code ? item.transferee_code : "" }}
              </td>
              <td>
                {{ item.transferee_name ? item.transferee_name : "" }}
              </td>
              <td>{{ formatDate(item.createdAt.substring(0, 10)) }}</td>

              <td>
                {{ item.final_price ? item.final_price : "" }}
              </td>

              <td
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: #3ca76c; font-weight: 500'
                    : item.status === '0'
                    ? 'cursor: ; color: #0098DA; font-weight: 500'
                    : 'cursor: ; color: red; font-weight: 500'
                "
              >
                {{
                  item.status === "1"
                    ? "อนุมัติโอนสินค้า"
                    : item.status === "0"
                    ? "รออนุมัติใบโอนสินค้า"
                    : "ไม่อนุมัติ"
                }}
              </td>
              <td>
                <div class="row">
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success me-sm-2 p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i
                        :class="
                          item.status === '0'
                            ? 'bi bi-pencil-square'
                            : 'bi bi-eye-fill'
                        "
                      ></i>
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import transferApi from "@/api/transfer/";
import moment from "moment";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";

export default {
  components: {
    DialogConfirmByPass,
    Loader,
    Pagination,
  },
  setup() {
    document.title = "BELUCA | สร้างใบรับสินค้าเข้า";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    companyItems: [],

    showing1: null,

    dialogConfirmByPass: false,
    isExport: false,
    loadingExport: false,
    indexExport: "",

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    dateSearch: "",
    allData: [],
  }),

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await transferApi.transferProduct.getAll({
          transfer_type: "1",
          companyId: companyId,
          companyBranchId: companyBranchId,
          status: "0",
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        console.log(responseData.data);
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push("/transfer-product/TP3-1/new");
    },
    goToEdit(id) {
      this.$router.push({
        path: "/transfer-product/TP3-1/edit",
        query: {
          id: id,
        },
      });
    },
    goToItemDetail(item) {
      this.$router.push({
        path: "/transfer-product/TP3-1/edit",
        query: {
          id: item.id,
          status: item.status,
        },
      });
    },
    gotoImportExcel() {
      this.$router.push("/warehouse/WH4/importexcel");
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.transfer_no} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await transferApi.transferProduct.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    handleApprove() {
      this.dialogConfirmByPass = true;
    },
    submitConfirmPass() {
      this.isApprove = true;
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.isApprove = false;
      this.dialogConfirmByPass = false;
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },

    async search() {
      this.loading = true;
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      const responseSearch = await transferApi.transferProduct.search(
        {
          search: this.searchInput,
          status: "0",
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
          transfer_type: "1",
        }
      );

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
